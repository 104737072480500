<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import '@/css/include/hhrest.scss'
import '@/css/news/index.scss'
export default {
  name: "home",
  mounted() {
    const url = window.location.href;
    const params = this.$global.getUrlParams(url);
    console.log(params, 'params')
    let type = params.type
    var typeToCidMap = {
      'Economy': 7942730317,
      'Gaming': 1412980987,
      'Sports': 4195056995,
      'Entertainment': 5681784084,
      'Beauty': 5366577982,
      'Health': 7992741324,
      'Films': 6679659658
    };
    let cid = typeToCidMap[type] || 1412980987;
    console.log(cid, 'cid------->')
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9543177256867205';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-ad-channel', cid);
    document.head.appendChild(script);
  },
};
</script>

