import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constRoutes = [
	{
		path: '/',
		// redirect: "/display",
		component: () => import('@/pages/index/assets/home.vue'),
		name: 'index',
	},
	{
		path: '/home',
		component: () => import('@/pages/index/assets/home.vue'),
		name: 'home',
	},
		{
		path: '/landing',
		component: () => import('@/pages/index/assets/landing.vue'),
		name: 'landing',
	},


]
export default new Router({
	base: process.env.BASE_URL,
	// mode: "history",
	routes: constRoutes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})
